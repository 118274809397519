
import { disabledTableData, tagIdx } from "@/composition/store";
import { useRequestDiagnoses } from "@/composition/index";
export default {
  setup() {
    const { deleteDiagnoses } = useRequestDiagnoses();
    const printID = (e) => {
      console.log(e);
    };
    return { disabledTableData, printID, tagIdx, deleteDiagnoses };
  }
};
