
import { computed, ref } from "vue";
import { useRequestDiagnoses } from "@/composition/index";
import { disabledTableData, page, selectPhoneRemove } from "@/composition/store";
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const { t, locale } = useI18n();
    const { requestDisableDiagnoses } = useRequestDiagnoses();
    // const page = ref(1);
    const pageNumber = computed(() => {
      return Math.ceil(disabledTableData.counts / 10);
    });
    // const pageParameter = ref(0);
    const next = () => {
      if (
        pageNumber.value > page.value.remove &&
        page.value.remove !== pageNumber.value
      ) {
        page.value.remove++;
        requestDisableDiagnoses(page.value.remove, selectPhoneRemove.value);
      } else {
        return;
      }
    };
    const previous = () => {
      if (pageNumber.value >= page.value.remove && page.value.remove !== 1) {
        page.value.remove--;
        requestDisableDiagnoses(page.value.remove, selectPhoneRemove.value);
        return;
      } else if (page.value.remove === 1) {
        return;
      }
    };
    return {
      pageNumber,
      requestDisableDiagnoses,
      next,
      previous,
      page,
      selectPhoneRemove,
      t,
      locale
    };
  }
};
