
import { computed, ref } from "vue";
import { useRequestDiagnoses } from "@/composition/index";
import { countNumber, page } from "@/composition/store";
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const { t, locale } = useI18n();
    const { requestDiagnoses } = useRequestDiagnoses();
    const pageNumber = computed(() => {
      return Math.ceil(countNumber.value / 10);
    });
    page.value.diagnoses = 1;
    // const page = ref(1);
    // const pageParameter = ref(0);
    const next = () => {
      if (
        pageNumber.value > page.value.diagnoses &&
        page.value.diagnoses !== pageNumber.value
      ) {
        page.value.diagnoses++;
        requestDiagnoses(page.value.diagnoses);
      } else {
        return;
      }
    };
    const previous = () => {
      if (pageNumber.value >= page.value.diagnoses && page.value.diagnoses !== 1) {
        page.value.diagnoses--;
        requestDiagnoses(page.value.diagnoses);
        return;
      } else if (page.value.diagnoses === 1) {
        return;
      }
    };
    return {
      countNumber,
      pageNumber,
      requestDiagnoses,
      next,
      previous,
      page,
      t,
      locale
    };
  }
};
