
import { dbNum } from "@/composition/store";
import Diagnoses from "@/components/diagnoses/DiagnosesListComponent.vue";
import DiagnosesListComponentBySpeciaUser from "@/components/diagnoses/DiagnosesListComponentBySpeciaUser.vue";
import Pagination from "@/components/diagnoses/PaginationComponent.vue";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
export default {
  components: {
    Diagnoses,
    DiagnosesListComponentBySpeciaUser,
    Pagination
    // DeleteDiagnoses
  },
  setup() {
    const { t, locale } = useI18n();
    const userinfoStr = localStorage.getItem('userinfo')
    const userInfo = userinfoStr ? JSON.parse(userinfoStr) : null
    // 指定用户账号 25358830
    const isSpecialUser =computed(() => userInfo?.phone === '25358830')
    return { dbNum, t, locale,isSpecialUser };
  }
};
