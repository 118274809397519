
import { onMounted, defineComponent } from "vue";
import DeleteDiagnosesModal from "@/components/remove/modal/DeleteDiagnosesModal.vue";
import Pagination from "@/components/remove/PaginationComponent.vue";
import {
  dbNum,
  page,
  disabledTableData,
  modalData,
  selectPhoneRemove
} from "@/composition/store";
import { useRequestDiagnoses } from "@/composition/index";
import { useI18n } from "vue-i18n";
export default defineComponent({
  components: { DeleteDiagnosesModal, Pagination },
  setup() {
    const { t, locale } = useI18n();
    const { requestDisableDiagnoses, isEnableDiagnoses } = useRequestDiagnoses();
    onMounted(async () => {
      await requestDisableDiagnoses(page.value.remove, selectPhoneRemove.value);
    });
    return {
      dbNum,
      page,
      disabledTableData,
      isEnableDiagnoses,
      modalData,
      selectPhoneRemove,
      requestDisableDiagnoses,
      t,
      locale
    };
  }
});
