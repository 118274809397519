import { toDisplayString as _toDisplayString, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "navbar navbar-light" }
const _hoisted_3 = { class: "container-fluid justify-content-between" }
const _hoisted_4 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("nav", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("h4", null, _toDisplayString($setup.t("diagnoseslist.totalpages")) + _toDisplayString($setup.pageNumber), 1),
        _createVNode("form", _hoisted_4, [
          _createVNode("button", {
            class: "btn btn-secondary me-2 text-nowrap",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.previous && $setup.previous(...args)))
          }, _toDisplayString($setup.t("diagnoseslist.pages.previous")), 1),
          _withDirectives(_createVNode("input", {
            class: "form-control me-2",
            type: "search",
            "aria-label": "Search",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ($setup.page.diagnoses = $event))
          }, null, 512), [
            [_vModelText, $setup.page.diagnoses]
          ]),
          _createVNode("button", {
            class: "btn btn-outline-success me-2 text-nowrap",
            type: "submit",
            onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.requestDiagnoses($setup.page.diagnoses)))
          }, _toDisplayString($setup.t("diagnoseslist.pages.go")), 1),
          _createVNode("button", {
            class: "btn btn-secondary btn-sm text-nowrap",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($setup.next && $setup.next(...args)))
          }, _toDisplayString($setup.t("diagnoseslist.pages.next")), 1)
        ])
      ])
    ])
  ]))
}