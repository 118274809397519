
import { defineComponent } from "vue";
import { useRequestDiagnoses, useShowECGChart } from "@/composition/index";
import {
  page,
  dbNum,
  token,
  selectPhone,
  insertTagName,
  diagnosesList
} from "@/composition/store";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
export default defineComponent({
  setup() {
    const { t, locale } = useI18n();
    const {
      requestDiagnoses,
      dataCounts,
      countNumber,
      searchPhoneData,
      deleteDiagnoses,
      switchSynthetic,
      isEnableDiagnoses
    } = useRequestDiagnoses();
    const route = useRoute();
    const { showECGChart } = useShowECGChart();
    onMounted(async () => {
      dbNum.value = route.params.dbNum;
      await requestDiagnoses(page.value.diagnoses);
      await dataCounts();
    });
    return {
      countNumber,
      showECGChart,
      dbNum,
      selectPhone,
      searchPhoneData,
      token,
      insertTagName,
      deleteDiagnoses,
      switchSynthetic,
      isEnableDiagnoses,
      t,
      locale,
      diagnosesList
    };
  }
});
