import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Diagnoses = _resolveComponent("Diagnoses")!
  const _component_DiagnosesListComponentBySpeciaUser = _resolveComponent("DiagnosesListComponentBySpeciaUser")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("h1", null, _toDisplayString($setup.t("diagnoseslist.diagnoses")) + " " + _toDisplayString($setup.dbNum), 1),
    (!$setup.isSpecialUser)
      ? (_openBlock(), _createBlock(_component_Diagnoses, { key: 0 }))
      : _createCommentVNode("", true),
    ($setup.isSpecialUser)
      ? (_openBlock(), _createBlock(_component_DiagnosesListComponentBySpeciaUser, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Pagination)
  ], 64))
}